import React, { useState, useEffect, useRef } from 'react';

import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import NavigationBar from 'components/common/NavigationBar';
import KoreaRadarChart from 'components/Chart/KoreaRadarChart';
import KoreaBarChart from 'components/Chart/KoreaBarChart';
import Query from '../graphql/query';
import ActivityIndicator from '../components/common/ActivityIndicator';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { usePopupManager } from "react-popup-manager";
import LayerPopup from "../components/common/LayerPopup";

const KoreanNewsNetwork = (props) => {
  const barRef = useRef()
  const radarRef = useRef()
  const popupManager = usePopupManager();

  // 캘린더에서 표시될 나머지 월에 해당하는 리스트
  const [category, setCategory] = useState("ecEconomy");
  const [nameList, setNameList] = useState([]);
  const [indexList, setIndexList] = useState(["Corona Recession"]);
  const [radarData, setRadarData] = useState([]);
  const [minDate, setMinDate] = useState(new Date('2005-02-01'))
  const [maxDate, setMaxDate] = useState(new Date(moment().subtract(1, 'M').format('YYYY-MM-DD')))
  const [checkedList, updateCheckedList] = useState([moment().subtract(1, 'M').format('YYYY-MM')]);
  const [showedradarData, updateShowedRadar] = useState([]);
  const [radarNameList, setRadarNameList] = useState([]);
  const [calNameList, setCalNameList] = useState([moment().subtract(1, 'M').format('YYYY-MM')]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showBarTooltip, setShowBarTooltip] = useState(false);
  const [subplotData, setSubplotData] = useState();

  const {loading: loadingIndex, data: RadarIndexListData} = useQuery(Query.getRadarLegend)
  
  useEffect(() => {
    if (RadarIndexListData){
      setIndexList(RadarIndexListData.radarLegend.indexList)
      setNameList(RadarIndexListData.radarLegend.targets.slice(0,7))
      setMinDate(new Date(RadarIndexListData.radarLegend.start))
      setMaxDate(new Date(RadarIndexListData.radarLegend.end))
      updateCheckedList([moment(RadarIndexListData.radarLegend.end).format('YYYY-MM')])
    }
  }, [RadarIndexListData])

  const {loading:loadingRadar, data: radarQueryData} = useQuery(Query.getRadarData, {
    variables: {indexList: checkedList}
  })

  useEffect(() => {
    if (radarQueryData) {
      setRadarData(radarQueryData.radarChartDataset)
      setSubplotData(radarQueryData.subplotChart)
    }
  }, [radarQueryData])

  useEffect(() => {
    const newCheckedList = [
      ...radarNameList,
      ...calNameList
    ]
    updateCheckedList(newCheckedList);
  }, [radarNameList, calNameList])

  useEffect(() => {
    updateShowedRadar([])
    if (checkedList.length > 0){
      var checkedRadarData = radarData.filter(r => checkedList.includes(r.name)).map((rdata) => {
        return ({
          name: rdata.name,
          data: rdata.data
        })
      })
      updateShowedRadar(checkedRadarData)
    }
  }, [checkedList, radarData])


  const handleRadar = (e) => {
    const targetName = e.target.value
    const currentIndex = checkedList.indexOf(targetName)
    const radarNameIndex = radarNameList.indexOf(targetName)
    const newRadarNameList = [...radarNameList]
    if (currentIndex === -1) {
      if (checkedList.length > 4) {
        popupManager.open(LayerPopup, {
          title: "최대 5개까지 동시조회 가능합니다.",
        });
      } else {
        newRadarNameList.push(e.target.value)
      }
    } else {
      newRadarNameList.splice(radarNameIndex, 1)
    }
    newRadarNameList.sort()
    setRadarNameList(newRadarNameList)
  }

  // 수정필요 or 캘린더핸들러에 로직을 포함시켜야함
  const cancelMonth = (e) => {
    const currentCalIndex = calNameList.indexOf(e.target.value)
    const oldCalNameList = [...calNameList]
    oldCalNameList.splice(currentCalIndex, 1)
    oldCalNameList.sort()
    setCalNameList(oldCalNameList)
  }

  const calenderHandler = (e) => {
    const monthYear = moment(e).format('YYYY-MM')
    const checkedIndex = checkedList.indexOf(monthYear)
    const calenderIndex = calNameList.indexOf(monthYear)
    const newCalNameList = [...calNameList]
    if (checkedIndex === -1) {
      if (checkedList.length > 4) {
        popupManager.open(LayerPopup, {
          title: "최대 5개까지 동시조회 가능합니다.",
        });
      } else {
        newCalNameList.push(monthYear)
      }
    } else {
      newCalNameList.splice(calenderIndex, 1)
    }
    newCalNameList.sort()
    setCalNameList(newCalNameList)
  }

  const DetailTabButton = (props) => {
    const { label, categoryName } = props;
    return (
      <button 
        type='button'
        className={category === categoryName? 'btnSelected' : ''} 
        onClick={() => setCategory(categoryName)}>{label}</button>
    )
  };

  return (
    <>
      <NavigationBar/>
        <div className='container'>
          <div className='content'>
            <div>
              <div className='header'>
                <h2>KOR NEWS<br/>NETWORK</h2>
                <div className='formOption'>
                  <span className='tit'>Events to View {checkedList.length}/5</span>
                  <div className='btnGroup'>
                  {nameList.map((value, index) => {
                    return (
                      <button
                        key={index}
                        type='button' 
                        className={checkedList.indexOf(value) !== -1 ? `btnChecked-${checkedList.indexOf(value)}` : ''} 
                        onClick={handleRadar} 
                        value={value}
                      >
                        {value}
                      </button>
                    );
                  })}
                  </div>
                  <div className='inDate'>
                  {calNameList.map((value, index) => {
                    return (
                      <span key={index} onClick={cancelMonth} value={value} className={checkedList.indexOf(value) !== -1 ? `date-${checkedList.indexOf(value)}` : ''}>
                      {value}
                        <button
                        key={index}
                        className={`cancelCal-${checkedList.indexOf(value)}`}
                        type='button'
                        value={value}
                        >
                        {/* <span className='offscr'>삭제</span>   */}
                      </button>
                    </span>
                    )
                  })}
                    <div className='pickerWrap'>
                      <DatePicker 
                        className='btnDate' 
                        dateFormat='yyyy-MM' 
                        showMonthYearPicker
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={calenderHandler}/>
                        
                    </div>
                  </div>
                </div>
              </div>
              <div className='knnSection'>
              {(loadingIndex || loadingRadar) ? 
                      (<ActivityIndicator/>)
                      :<>
                {/* 방사형그래프 */}
                <div className='sector' ref={radarRef}>
                  <div className='sectionTitle'>
                    <div className={`tooltipWrap ${showTooltip ? 'active' : ''}`}>
                      <div className='tooltipAlign'>
                        <span>Radar Chart</span>
                        <button 
                          type='button' 
                          className='btnTooltip'
                          onMouseEnter={() => {setShowTooltip(true);}}
                          onMouseLeave={() => {setShowTooltip(false);}}
                          onClick={() => {setShowTooltip(!showTooltip);}}
                        >
                          <span className='offscr'>설명보기</span>
                        </button>
                      </div>
                      <div className='tooltip'>
                        <div className='exp'>
                          금융시장을 80여개 요인으로 분석하여 14개 요인으로 요약하였습니다.<br/>
                          주요 금융위기 시황 혹은 특정 시기를 최대 5개까지 비교하실 수 있습니다.<br/> 
                          면적이 넓을수록 시장이 안정적이며, 좁을수록 불안정합니다.
                        </div>
                      </div>
                    </div>
                    <div className='moveRadar'>
                      <div className='tooltipAlign'>
                        <span>Detail</span>
                        <button
                          type='button'
                          className='btnMoveTobar'
                          onClick={() => barRef.current.scrollIntoView(false)}
                        />
                      </div>
                    </div>
                  </div>
                {showedradarData.length > 0 && indexList &&
                  <KoreaRadarChart data={showedradarData} index={indexList} legendList={checkedList}/>
                }
                </div>
                {/* 막대그래프  */}
                <div className='sector' style={{marginTop:'60px'}} ref={barRef}>
                  <div className='sectionTitle'>
                    <div className={`tooltipWrap ${showBarTooltip ? 'active' : ''}`}>
                      <div className='tooltipAlign'>
                        <span>Detail</span>
                        <button 
                          type='button' 
                          className='btnTooltip'
                          onMouseEnter={() => {setShowBarTooltip(true);}}
                          onMouseLeave={() => {setShowBarTooltip(false);}}
                          onClick={() => {setShowBarTooltip(!showTooltip);}}
                        >
                          <span className='offscr'>설명보기</span>
                        </button>
                      </div>
                      <div className='tooltip detail'>
                        <div className='exp'>
                          14개의 각 요인을 구성하는 상세 요인에 따른 긍/부정 뉴스비중을 확인할 수 있습니다.<br/>
                          긍/부정뉴스의 상대평가로 숫자가 높을수록 반대의견에 비해 높은 비중으로 나타난다고 분석할 수 있습니다.
                        </div>
                      </div>
                    </div>
                    <div className='moveRadar'>
                      <div className='tooltipAlign'>
                        <span>Radar Chart</span>
                        <button
                          type='button'
                          className='btnMoveToRadar'
                          onClick={() => radarRef.current.scrollIntoView(false)}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{display:'flex', marginBottom: '24px'}}>
                    <div className='tagGroup'>
                      <ul style={{marginTop:'3px'}}>
                        <li key='tag_economy'>
                          <span className='tag gr'>
                            <em>경제요인</em>
                            <DetailTabButton label='경기' categoryName='ecEconomy' />
                            <DetailTabButton label='금융시장' categoryName='ecFinance' />
                            <DetailTabButton label='정책' categoryName='ecPolicy' />
                          </span>
                        </li>
                        <li key='tag_abroad'>
                          <span className="tag or">
                            <em>해외요인</em>
                            <DetailTabButton label='경기' categoryName='ifEconomy' />
                            <DetailTabButton label='금융시장' categoryName='ifFinance' />
                            <DetailTabButton label='정책' categoryName='ifPolicy' />
                            <DetailTabButton label='미분류' categoryName='ifOther' />
                          </span>
                        </li>
                        <li key='tag_inc'>
                          <span className="tag pu">
                            <em>기업요인</em>
                            <DetailTabButton label='경영내용' categoryName='coOperation' />
                            <DetailTabButton label='사업내용' categoryName='coBusiness' />
                            <DetailTabButton label='손익' categoryName='coProfit' />
                            <DetailTabButton label='재무상태' categoryName='coConditions' />
                            <DetailTabButton label='미분류' categoryName='coOther' />
                          </span>
                        </li>
                        <li key='tag_etc'>
                          <span className="tag gy">
                            <em>etc</em>
                            <DetailTabButton label='기타' categoryName='otherOther' />
                            <DetailTabButton label='미분류' categoryName='etcEtc' />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {subplotData && (checkedList.length > 0) &&
                    <div>
                      <div className="barChartPrefix">
                        <div className='barChartLegend-1'/>
                        <div className='barChartLegend-2'>부정</div>
                        <div className='barChartLegend-3'>긍정</div>
                        <div/>
                      </div>
                      <KoreaBarChart data={subplotData[category]}/>
                    </div>
                  }
                </div>
                </>}
              </div>
            </div>
          </div>
        </div>
    </>
  );
}; 

export default withRouter(KoreanNewsNetwork);